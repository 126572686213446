import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Impressum from './ImpressumComponent';

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const languages = [
    { code: "en", name: "English", icon: "🇬🇧" },
    { code: "de", name: "Deutsch", icon: "🇩🇪" },
    { code: "fr", name: "Français", icon: "🇫🇷" },
  ];

  const getCurrentLang = () => {
    const cur = i18n.language;
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].code === cur) {
        return languages[i];
      }
    }
    return languages[0];
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLang());

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language.code);
  };

  const handleImpressumClick = () => {
    setShowModal(true);
  };

  const handleImpressumClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/">
            <img
              src="img/alpinemeteo-logo-icon.png"
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="AlpineMeteo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">AlpineMeteo</Nav.Link>
              <Nav.Link href="/allstations">{t("nav.maps")}</Nav.Link>
              <Nav.Link href="/shadow">{t("nav.maps.shade")}</Nav.Link>
              <Nav.Link href="/compare">{t("nav.compare")}</Nav.Link>
              <Nav.Link href="/hsgam">{t("nav.hsgam")}</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link onClick={handleImpressumClick}>{t("nav.contact")}</Nav.Link>
              <NavDropdown
                align="end"
                popperConfig={{ modifiers: [{ name: 'preventOverflow', options: { boundary: 'viewport' } }] }}
                style={{ zIndex: "1004" }}
                title={<span>{selectedLanguage.icon}</span>}
                id='collapsible-nav-dropdown'>
                {languages.map((language) => (
                  <NavDropdown.Item
                    key={language.code}
                    style={{ "textAlign": "center" }}
                    onClick={() => handleLanguageSelect(language)}
                  >
                    <span>{language.icon}   {language.name}</span>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Impressum open={showModal} onClose={() => { handleImpressumClose(); }} />
    </>
  );
};

export default Navigation;

