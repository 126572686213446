import React from 'react';
import { RWebShare } from "react-web-share";
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import Grid from '@mui/material/Grid2';

const ShareButton = React.memo((props) => {
   return <RWebShare
      data={{
         url: props.url,
         title: props.title,
      }}
      sites={["copy", "whatsapp", "telegram", "facebook"]}
      onClick={() => console.log("share!")}
   >
      <Button variant="contained" startIcon={<ShareIcon />}>
         Share
      </Button>
   </RWebShare>
});

const Footer = () => {
   const currentYear = new Date().getFullYear(); // Holt das aktuelle Jahr
   const startYear = 2023; // Jahr, ab dem dein Copyright gilt

   return (
      <footer style={footerStyle}>
         <div style={bottomStyle}>
            <Grid container>
               <Grid size={4} style={{textAlign: 'left', margin: 'auto'}}>
                  <p style={{ margin: "auto" }}>
                     {startYear === currentYear ? currentYear : `${startYear} - ${currentYear}`}
                  </p>
               </Grid>
               <Grid size={4} style={{textAlign: 'center', margin: 'auto'}}>
                  <p style={{ margin: "auto" }}>
                     AlpineMeteo
                  </p>
               </Grid>               
               <Grid size={4} style={{textAlign: 'right'}}>
                  <ShareButton content={"AlpineMeteo"} url={"https://www.alpinemeteo.com"} title={"AlpineMeteo"} />
               </Grid>
            </Grid>
         </div>
      </footer>
   );
};

const footerStyle = {
   background: '#333',
   color: '#fff',
   padding: '12px 0',
   margin: 'auto',
   position: 'relative',
   bottom: '0',
   width: '100%',
   fontFamily: 'Ubuntu'
};

const bottomStyle = {
   margin: 'auto',
   paddingLeft: '20px',
   paddingRight: '20px' 
};

export default Footer;